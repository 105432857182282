export const ProfileLicenseState = {
    name: "",
    address1: "",
    address2: "",
    address3: "",
    country: "",
    county: "",
    state: "",
    zipcode: "",
    fax_number: ""
}

