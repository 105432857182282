import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import English from "../Language/locales/english.json";
import French from "../Language/locales/french.json";
import Spanish from "../Language/locales/spanish.json";
import Korean from "../Language/locales/korean.json";
import Vietnamese from "../Language/locales/vietnamese.json";
import Hindi from "../Language/locales/hindi.json";
import Urdu from "../Language/locales/urdu.json";
import Chinese from "../Language/locales/chinese.json";
import German from "../Language/locales/german.json";
import Afrikaans from "../Language/locales/afrikaans.json";
import Thai from "../Language/locales/thai.json";
import Malay from "../Language/locales/malay.json";
import Dutch from "../Language/locales/dutch.json"
import Hungarian from "../Language/locales/hungarian.json"
import Irish from "../Language/locales/irish.json"
import Latin from "../Language/locales/latin.json"
import Latvian from "../Language/locales/latvian.json"
import Lithuanian from "../Language/locales/lithuanian.json"
import Polish from "../Language/locales/poland.json"
import Portuguese from "../Language/locales/portugese.json"
import Romanian from "../Language/locales/romanian.json"
import Turkish from "../Language/locales/turkish.json"
import Zulu from "../Language/locales/zulu.json"
import Indonesian from "../Language/locales/indonesian.json"
import Danish from "../Language/locales/danish.json"
import Bosanski from "../Language/locales/bosnian.json"
import Estonian from "../Language/locales/estonian.json"
import Galician from "../Language/locales/galician.json"
import Icelandic from "../Language/locales/icelandic.json"
import Italian from "../Language/locales/italian.json"
import Norwegian from "../Language/locales/norwegian.json"
import Albanian from "../Language/locales/albanian.json"
import Swedish from "../Language/locales/swedish.json"
import Yoruba from "../Language/locales/yoruba.json"
import Hawaiian from "../Language/locales/hawaiian.json"
import Greek from "../Language/locales/greek.json"
import Mongolian from "../Language/locales/mongolian.json"
import Serbian from "../Language/locales/serbian.json"
import Bulgarian from "../Language/locales/bulgarian.json"
import Russian from "../Language/locales/russian.json"
import Ukrainian from "../Language/locales/ukrainian.json"
import Kazakh from "../Language/locales/kazakh.json"
import Armenian from "../Language/locales/armenian.json"
import Yiddish from "../Language/locales/yiddish.json"
import Sindhi from "../Language/locales/sindhi.json"
import Arabic from "../Language/locales/arabic.json"
import Persian from "../Language/locales/persian.json"
import Pashto from "../Language/locales/pashto.json"
import Nepali from "../Language/locales/nepali.json"
import Marathi from "../Language/locales/marathi.json"
import Bengali from "../Language/locales/bengali.json"
import Punjabi from "../Language/locales/punjabi.json"
import Gujarati from "../Language/locales/gujarati.json"
import Tamil from "../Language/locales/tamil.json"
import Telugu from "../Language/locales/telugu.json"
import Kannada from "../Language/locales/kannada.json"
import Malayalam from "../Language/locales/malayalam.json"
import Sinhala from "../Language/locales/sinhala.json"
import Lao from "../Language/locales/lao.json"
import Georgian from "../Language/locales/georgian.json"
import Amharic from "../Language/locales/amharic.json"
import Khmer from "../Language/locales/khmer.json"
import Japanese from "../Language/locales/japanese.json"
import TraditionalChinese from "../Language/locales/traditionalChinese.json"
import Hebrew from "../Language/locales/hebrew.json"
import Cestina from "../Language/locales/cestina.json"
import Azerbaijani from "../Language/locales/azerbaijani.json"
import Tajik from "../Language/locales/tajik.json"
import Kenya from "../Language/locales/kenya.json"
import Luxembourg from "../Language/locales/luxembourg.json"
import Macedonia from "../Language/locales/macedonia.json"

const fallbackLng = ["english"];
const availableLanguages = [
  "english",
  "french",
  "spanish",
  "korean",
  "vietnamese",
  "hindi",
  "urdu",
  "chinese",
  "german",
  "afrikaans",
  "thai",
  "malay",
  "dutch",
  "hungarian",
  "irish",
  "latin",
  "latvian",
  "lithuanian",
  "poland",
  "portugese",
  "romanian",
  "turkish",
  "zulu",
  "indonesian",
  "danish",
  "bosnian",
  "estonian",
  "galician",
  "icelandic",
  "italian",
  "norwegian",
  "albanian",
  "swedish",
  "yoruba",
  "hawaiian",
  "greek",
  "mongolian",
  "serbian",
  "bulgarian",
  "russian",
  "ukrainian",
  "kazakh",
  "armenian",
  "yiddish",
  "sindhi",
  "arabic",
  "persian",
  "pashto",
  "nepali",
  "marathi",
  "bengali",
  "punjabi",
  "gujarati",
  "tamil",
  "telugu",
  "kannada",
  "malayalam",
  "sinhala",
  "lao",
  "georgian",
  "amharic",
  "khmer",
  "japanese",
  "traditionalChinese",
  "hebrew",
  "cestina",
  "azerbaijani",
  "tajik",
  "kenya",
  "luxembourg",
  "macedonia",
];

const resources = {
  english: {
    translation: English,
  },
  french: {
    translation: French,
  },
  spanish: {
    translation: Spanish,
  },
  korean: {
    translation: Korean,
  },
  vietnamese: {
    translation: Vietnamese,
  },
  hindi: {
    translation: Hindi,
  },
  urdu: {
    translation: Urdu,
  },
  chinese: {
    translation: Chinese,
  },
  german: {
    translation: German,
  },
  afrikaans: {
    translation: Afrikaans,
  },
  thai: {
    translation: Thai,
  },
  malay: {
    translation: Malay,
  },
  dutch: {
    translation: Dutch,
  },
  hungarian: {
    translation: Hungarian,
  },
  irish: {
    translation: Irish,
  },
  latin: {
    translation: Latin,
  },
  latvian: {
    translation: Latvian,
  },
  lithuanian: {
    translation: Lithuanian,
  },
  poland: {
    translation: Polish,
  },
  portugese: {
    translation: Portuguese,
  },
  romanian: {
    translation: Romanian,
  },
  turkish: {
    translation: Turkish,
  },
  zulu: {
    translation: Zulu,
  },
  indonesian: {
    translation: Indonesian,
  },
  danish: {
    translation: Danish
  },
  bosnian: {
    translation: Bosanski
  },
  estonian: {
    translation: Estonian
  },
  galician: {
    translation: Galician
  },
  icelandic: {
    translation: Icelandic
  },
  italian: {
    translation: Italian
  },
  norwegian: {
    translation: Norwegian
  },
  albanian: {
    translation: Albanian
  },
  swedish: {
    translation: Swedish
  },
  yoruba: {
    translation: Yoruba
  },
  hawaiian: {
    translation: Hawaiian
  },
  greek: {
    translation: Greek
  },
  mongolian: {
    translation: Mongolian
  },
  serbian: {
    translation: Serbian
  },
  bulgarian: {
    translation: Bulgarian
  },
  russian: {
    translation: Russian
  },
  ukrainian: {
    translation: Ukrainian
  },
  kazakh: {
    translation: Kazakh
  },
  armenian: {
    translation: Armenian
  },
  yiddish: {
    translation: Yiddish
  },
  sindhi: {
    translation: Sindhi
  },
  arabic: {
    translation: Arabic
  },
  persian: {
    translation: Persian
  },
  pashto: {
    translation: Pashto
  },
  nepali: {
    translation: Nepali
  },
  marathi: {
    translation: Marathi
  },
  bengali: {
    translation: Bengali
  },
  punjabi: {
    translation: Punjabi
  },
  gujarati: {
    translation: Gujarati
  },
  tamil: {
    translation: Tamil
  },
  telugu: {
    translation: Telugu
  },
  kannada: {
    translation: Kannada
  },
  malayalam: {
    translation: Malayalam
  },
  sinhala: {
    translation: Sinhala
  },
  lao: {
    translation: Lao
  },
  georgian: {
    translation: Georgian
  },
  amharic: {
    translation: Amharic
  },
  khmer: {
    translation: Khmer
  },
  japanese: {
    translation: Japanese
  },
  traditionalChinese: {
    translation: TraditionalChinese
  },
  hebrew: {
    translation: Hebrew
  },
  cestina: {
    translation: Cestina
  },
  azerbaijani: {
    translation: Azerbaijani
  },
  tajik: {
    translation: Tajik
  },
  kenya: {
    translation: Kenya
  },
  luxembourg: {
    translation: Luxembourg
  },
  macedonia: {
    translation: Macedonia
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true,
    },
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
