import "bootstrap/dist/css/bootstrap.min.css";
import { createBrowserHistory } from "history";
import React, { lazy, Suspense, useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { connect } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import getSuggestedPlans from "./api/plans/suggestedPlans";
import useLocalStorage from "./effects/LocalStorage/use-local-storage";
import "./global.scss";
import { setusername } from "./manageState/ProviderHeader";
import GAListener from "./utils/google-analytics";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
const HomeLayout = lazy(() => import("./layouts/home.layout"));
const Podcast = lazy(() => import("./layouts/podcast.layout"));
const ProviderLayout = lazy(() => import("./layouts/provider.layout"));
const StaticPageLayout = lazy(() => import("./layouts/static_page_layout"));
const ThankYou = lazy(() => import("./components/shared/thankyou"));
const ResetPassword = lazy(() => import("./components/resetPassword"));
const ProviderDashboard = lazy(() => import("./components/provider/providerDashboard"));
const HomeShare = lazy(() => import("./components/provider/homeShare"));
const InternalLayoutView = lazy(() => import("./layouts/internal.layout.view"));
const InternalLayoutCustomer = lazy(() => import("./layouts/internal.layout.customer"));
const AccountDetails = lazy(() => import("./components/accountDetails"));
const Checkout = lazy(() => import("./components/accountDetails/checkout"));
const DocCallback = lazy(() => import("./components/docusign/DocCallback"));
const AccountDetailsCustomer = lazy(() => import("./components/accountDetailsCustomer"));
const CheckoutCustomer = lazy(() => import("./components/customer/checkout"));
const CallbackSuccess = lazy(() => import("./components/stripe/callbackSuccess"));
const CallbackFailure = lazy(() => import("./components/stripe/callbackFailure"));
const CustomerLandingLayout = lazy(() => import("./layouts/customer.landing.layout"));
const ConfirmBooking = lazy(() => import("./components/customer/checkout/confirmBooking"));
const ThankYouAfterSignUp = lazy(() => import("./components/shared/thankYouAfterSignUp") );
const AskForDemo = lazy(() => import("./components/newToMihygge/askForDemo/askForDemo"));
const CelebrateUS = lazy(() => import("./components/celebrateUs/celebrateUs"));
const MihggyBenefits = lazy(() => import("../src/components/commons/mihggyBenefits"));
const StaticPages = lazy(() => import("./components/staticPages/staticPages"));
const StaticNewsLetter = lazy(() => import("./components/commons/static_news_letter"));
const MihggyBlog = lazy(() => import("./components/mihggyBlogs"));
const Episodelist = lazy(() => import("./components/podcast/episodelist"));
const Consumer_Testimonials = lazy(() => import("../src/components/commons/consumer_testimonials"));
const Episode = lazy(() => import("./components/podcast/episode"));
const Career = lazy(() => import("./components/careers"));
const GlobalSupport = lazy(() => import("./components/customer_support/global_support"));
const Pricing = lazy(() => import("./components/pricing/pricing"));
const PricingForm = lazy(() => import("./components/pricing/pricing_form"));
const WhereWeServe = lazy(() => import("./components/commons/where_we_serve"));
const OurBoard = lazy(() => import("./components/viewCare/ourBoard"));
const Provider = lazy(() => import("./layouts/provider"));
const Provider_Testimonials = lazy(() => import("./layouts/provider_testimonials"));
const Video = lazy(() => import("./layouts/video"));
const Videos = lazy(() => import("../src/components/podcast/video"));
const PropertyOwner = lazy(() => import("./components/viewCare//propertyOwner"));
const CountryTypeCare = lazy(() => import("./constants/customer/countryTypeCare"));
const Team = lazy(() => import("../src/components/commons/footer/team"));
const Press = lazy(() => import("../src/components/commons/footer/press"));
const Blog = lazy(() => import("./components/home/empowerment/blog"));
const Category_blog = lazy(() => import("./components/home/empowerment/category_blog"));
const HealthEducation = lazy(() => import("./components/podcast/healthEducation"));
const HealthEducationDestinations = lazy(() => import("./components/podcast/healthEducationDestinations"));
const VideoSubCategory = lazy(() => import("./components/podcast/videoSubCategory"));

const App = (props) => {
  const history = createBrowserHistory();
  const [username, _] = useLocalStorage("username", "");
  const [userDetails] = useLocalStorage("userDetails", {});
  const [planStatus, setPlanStatus] = useState(localStorage.getItem("PlanStatus") ?? "");
  const userRole = userDetails?.role;
  const userPlan = true; // userDetails?.plan_type;

  useEffect(() => {
    statusOfPlan();
  }, [planStatus]);

  const statusOfPlan = async () => {
    const plan_status = await getSuggestedPlans();

    if (plan_status && plan_status.data && plan_status.data.details) {
      let user_plan_active = plan_status.data.details.find(
        (item) => item.status === "pending"
      );
      if (
        user_plan_active &&
        user_plan_active.status === "pending" &&
        planStatus === ""
      ) {
        setPlanStatus("pending");
        localStorage.setItem("PlanStatus", "pending");
      }
    }
  };

  const trackingId = "UA-123401123-1"; //process.env.REACT_APP_GA_ID;

  useEffect(() => {
    props.setLoginUsername(username);
  }, []);

  return (
    <div>
      <Router history={history}>
        <GAListener trackingId={trackingId}>
        <Suspense fallback={<div>Loading...</div>}>
          {!!userRole ? (
            userRole === "provider" ? (
              planStatus === "pending" ? (
              <Switch>
                {/* Only Provider with Active Plan */}
                <Route path="/provider" component={ProviderDashboard} />
                <Route exact path="/pricing" component={Provider} />
                <Route
                  exact
                  path="/provider-testimonials"
                  component={Provider_Testimonials}
                />
                <Route path="/where-we-serve" component={WhereWeServe} />
                <Route
                  exact
                  path="/country-type-care"
                  component={CountryTypeCare}
                />
                <Route exact path="/video" component={Video} />
                <Route path="/homeshare" component={HomeShare} />
                <Route path="/account-details" component={AccountDetails} />
                <Route path="/ds_return" component={DocCallback} />
                <Route
                  path="/stripe/callback/success"
                  component={CallbackSuccess}
                />
                <Route
                  path="/stripe/callback/failure"
                  component={CallbackFailure}
                />
                <Route path="/checkout" component={Checkout} />
                <Route path="/provider_pricing" component={Pricing} />
                <Route path="/pricing_form/:id" component={PricingForm} />

                {/* Common Routes */}
                <Route exact path="/care/:id" component={InternalLayoutView} />
                <Route exact path="/thankyou" component={ThankYou} />
                <Route
                  exact
                  path="/thank-you"
                  component={ThankYouAfterSignUp}
                />
                <Route path="/reset_password" component={ResetPassword} />

                {/* If 404, redirect to provider landing page */}
                <Route
                  render={(props) => {
                    return <Redirect push to="/provider" />;
                  }}
                />
              </Switch>
            ) : (
              <Switch>
                {/* Only Provider without Active Plan */}
                <Route path="/account-details" component={AccountDetails} />
                <Route path="/ds_return" component={DocCallback} />
                <Route path="/stripe/callback/success" component={CallbackSuccess} />
                <Route path="/stripe/callback/failure" component={CallbackFailure} />
                <Route path="/checkout" component={Checkout} />
                <Route path="/provider_pricing" component={Pricing} />
                <Route path="/pricing_form/:id" component={PricingForm} />
                <Route exact path="/thankyou" component={ThankYou} />
                <Route exact path="/thank-you" component={ThankYouAfterSignUp} />
                <Route path="/reset_password" component={ResetPassword} />
                <Route render={() => { return <Redirect push to="/account-details" />; }} />
              </Switch>
            )) : (
              <Switch>
                {/* Only Customer */}
                <Route
                  exact
                  path="/customer"
                  component={CustomerLandingLayout}
                />
                <Route
                  path="/customer-search"
                  component={InternalLayoutCustomer}
                />
                <Route
                  path="/bookings/:id/confirm"
                  component={ConfirmBooking}
                />
                <Route
                  path="/account-details-customer"
                  component={AccountDetailsCustomer}
                />
                <Route path="/benefits" component={MihggyBenefits} />
                <Route path="/checkout-customer" component={CheckoutCustomer} />
                <Route exact path="/ask-for-demo" component={AskForDemo} />
                <Route exact path="/celebrate-us" component={CelebrateUS} />
                <Route exact path="/our-board" component={OurBoard} />
                <Route path="/property-owner" component={PropertyOwner} />
                <Route path="/category-blog/:id" exact>
                  <Category_blog />
                </Route>
                <Route path="/category-blog/:id/blog" exact>
                  <Blog />
                </Route>
                <Route
                  exact
                  path="/country-type-care"
                  component={CountryTypeCare}
                />

                {/* Common Routes */}
                <Route
                  exact
                  path="/bookingrcfe/:id"
                  component={InternalLayoutView}
                />
                <Route exact path="/care/:id" component={InternalLayoutView} />
                <Route exact path="/thankyou" component={ThankYou} />
                <Route
                  exact
                  path="/thank-you"
                  component={ThankYouAfterSignUp}
                />
                <Route path="/reset_password" component={ResetPassword} />

                {/* If 404, redirect to customer landing page */}
                <Route
                  render={(props) => {
                    return <Redirect push to="/customer" />;
                  }}
                />
              </Switch>
            )
          ) : (
            <Switch>
              {/* Guest Users */}
              <Route exact path="/" component={HomeLayout} />
              <Route exact path="/pricing" component={Provider} />
              <Route exact path="/linkedin" component={LinkedInCallback} />
              <Route
                exact
                path="/provider-registration"
                component={ProviderLayout}
              />
              <Route path="/benefits" component={MihggyBenefits} />
              <Route
                exact
                path="/customer-search"
                component={InternalLayoutCustomer}
              />
              <Route exact path="/ask-for-demo" component={AskForDemo} />
              <Route exact path="/celebrate-us" component={CelebrateUS} />
              <Route exact path="/our-board" component={OurBoard} />
              <Route path="/property-owner" component={PropertyOwner} />
              <Route
                exact
                path="/country-type-care"
                component={CountryTypeCare}
              />
              <Route path="/reset_password" component={ResetPassword} />
              <Route exact path="/thankyou" component={ThankYou} />
              <Route exact path="/thank-you" component={ThankYouAfterSignUp} />
              <Route exact path="/provider_pricing" component={Pricing} />
              <Route path="/assisted-living" exact>
                <StaticPages heading="Assisted Living" page="Assisted Living" />
              </Route>
              <Route path="/independent-senior-living" exact>
                <StaticPages
                  heading="Independent Senior Living"
                  page="Independent Senior Living"
                />
              </Route>

              <Route path="/care-homes" exact>
                <StaticPages heading="Care Homes" page="Care homes" />
              </Route>

              <Route path="/memory-care" exact>
                <StaticPages heading="Memory Care" page="Memory Care" />
              </Route>

              <Route path="/continuing-care-retirement-communities" exact>
                <StaticPages
                  heading="Continuing Care Retirement Communities"
                  page="CCRC"
                />
              </Route>

              <Route path="/nursing-homes-skilled-nursing-facilities" exact>
                <StaticPages
                  heading="Nursing Homes/Skilled Nursing Facilities"
                  page="SNF"
                />
              </Route>

              <Route path="/long-term-care" exact>
                <StaticPages heading="Long Term Care" page="Long Term Care" />
              </Route>

              <Route path="/senior-apartments" exact>
                <StaticPages
                  heading="Senior Apartments"
                  page="Senior Apartments"
                />
              </Route>

              <Route path="/co-operative-housing" exact>
                <StaticPages
                  heading="Co-operative Housing"
                  page="Co-operative Housing"
                />
              </Route>

              <Route path="/retirement-villages" exact>
                <StaticPages
                  heading="Retirement Villages"
                  page="Retirement Village"
                />
              </Route>

              <Route path="/hospice-palliative-care" exact>
                <StaticPages
                  heading="Hospice/Palliative Care"
                  page="Hospice/Palliative Care"
                />
              </Route>

              <Route path="/respite-care" exact>
                <StaticPages heading="Respite Care" page="Respite Care" />
              </Route>

              <Route path="/multi-unit-housing" exact>
                <StaticPages
                  heading="Multi-unit housing"
                  page="Multi-unit Housing"
                />
              </Route>

              <Route path="/vacation-care" exact>
                <StaticPages heading="Vacation Care" page="Vacation Care" />
              </Route>

              <Route path="/medical-tourism" exact>
                <StaticPages heading="Medical Tourism" page="Medical Tourism" />
              </Route>

              <Route path="/adult-day-care" exact>
                <StaticPages heading="Adult day Care" page="Adult Day Care" />
              </Route>

              <Route path="/newsletters" exact>
                <StaticNewsLetter />
              </Route>

              <Route path="/videos" exact>
                <Videos />
              </Route>

              <Route path="/videos/:id/sub_category" exact>
                <VideoSubCategory />
              </Route>

              <Route path="/mihygge-blog" exact>
                <MihggyBlog />
              </Route>

              <Route path="/category-blog/:id" exact>
                <Category_blog />
              </Route>

              <Route path="/category-blog/:id/blog" exact>
                <Blog />
              </Route>

              <Route path="/customer-support" exact>
                <GlobalSupport />
              </Route>
              <Route path="/consumer-refund" exact>
                <StaticPageLayout
                  heading="Consumer refund"
                  page="ConsumerRefund"
                />
              </Route>
              <Route path="/content" exact>
                <StaticPageLayout heading="Content" page="ContentPolicy" />
              </Route>
              <Route path="/copyright" exact>
                <StaticPageLayout heading="Copyright" page="Copyright" />
              </Route>
              <Route path="/nondiscrimination" exact>
                <StaticPageLayout
                  heading="Nondiscrimination"
                  page="Nondiscrimination"
                />
              </Route>
              <Route path="/privacy" exact>
                <StaticPageLayout heading="Privacy" page="Privacy" />
              </Route>
              <Route path="/terms-of-service" exact>
                <StaticPageLayout heading="FAQ" page="TermOfService" />
              </Route>
              <Route path="/about-us" exact>
                <StaticPageLayout heading="FAQ" page="AboutUs" />
              </Route>

              <Route path="/faq" exact>
                <StaticPageLayout heading="FAQ" page="FAQ" />
              </Route>

              <Route exact path="/podcasts" component={Podcast}></Route>

              <Route exact path="/podcasts/:id" component={Episodelist}></Route>

              <Route
                exact
                path="/podcasts/:id/episodes"
                component={Episode}
              ></Route>

              <Route
                exact
                path="/consumer-testimonials"
                component={Consumer_Testimonials}
              ></Route>

              <Route exact path="/team" component={Team}></Route>

              <Route exact path="/press" component={Press}></Route>

              <Route path="/mihygge-blog" exact>
                <MihggyBlog />
              </Route>

              <Route path="/customer-support" exact>
                <GlobalSupport />
              </Route>

              <Route path="/consumer-refund" exact>
                <StaticPageLayout
                  heading="Consumer refund"
                  page="ConsumerRefund"
                />
              </Route>

              <Route path="/content" exact>
                <StaticPageLayout heading="Content" page="ContentPolicy" />
              </Route>

              <Route path="/copyright" exact>
                <StaticPageLayout heading="Copyright" page="Copyright" />
              </Route>

              <Route path="/nondiscrimination" exact>
                <StaticPageLayout
                  heading="Nondiscrimination"
                  page="Nondiscrimination"
                />
              </Route>

              <Route path="/privacy" exact>
                <StaticPageLayout heading="Privacy" page="Privacy" />
              </Route>

              <Route path="/terms-of-service" exact>
                <StaticPageLayout heading="FAQ" page="TermOfService" />
              </Route>

              <Route path="/about-us" exact>
                <StaticPageLayout heading="FAQ" page="AboutUs" />
              </Route>

              <Route path="/faq" exact>
                <StaticPageLayout heading="FAQ" page="FAQ" />
              </Route>

              <Route path="/podcasts" exact>
                <Podcast />
              </Route>

              <Route path="/health_education" exact>
                <HealthEducation />
              </Route>

              <Route path="/health_education/:name/destinations" exact>
                <HealthEducationDestinations />
              </Route>

              <Route path="/careers" exact>
                <Career />
              </Route>

              <Route path="/confirm_email/:id">
                <StaticPageLayout heading="Confirm Email" page="ConfirmEmail" />
              </Route>

              {/* If 404, redirect to homepage */}
              <Route
                render={(props) => {
                  return <Redirect push to="/" />;
                }}
              />
            </Switch>
          )}
          </Suspense>
        </GAListener>
      </Router>
      <CookieConsent
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        location="bottom"
        buttonText="Agree!!"
        cookieName="mahigaacokiese"
        style={{ color: "#000", background: "#fff" }}
        buttonClasses="btn"
        overlay
      >
        Our website uses cookies to personalize your experience, perform
        analytics, and tailor ads. By using our site, you consent to our use of
        cookies.
      </CookieConsent>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setLoginUsername: (username) =>
      dispatch(setusername({ username: username })),
  };
}
export default connect(null, mapDispatchToProps)(App);
